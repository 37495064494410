import React from 'react';
import AuthImage from '../../img/auth';
import styles from './styles.module.scss';
import global_styles from '../global.module.scss';

export default function AuthQuestions() {
    return (
        <div className={`${styles.main_info_block} ${global_styles.main_section}`}>
            <div className={global_styles.new_main_container}>
                <div className={styles.main_info_block_list}>
                    <div className={styles.main_info_block_item}>
                        <img
                            src={AuthImage.images['feature-1.svg']}
                            width="100"
                            height="100"
                            alt="Register for free"
                            className={styles.main_info_block_item_ico}
                        />
                        <h3 className={styles.main_info_block_item_title}>Free registration</h3>
                        <p className={styles.main_info_block_item_text}>
                            No payments involved to become a member
                        </p>
                    </div>
                    <div className={styles.main_info_block_item}>
                        <img
                            src={AuthImage.images['feature-2.svg']}
                            width="100"
                            height="100"
                            alt="Free galleries"
                            className={styles.main_info_block_item_ico}
                        />
                        <h3 className={styles.main_info_block_item_title}>
                            Stunning visuals gratis
                        </h3>
                        <p className={styles.main_info_block_item_text}>
                            View thousands of quality pictures for free
                        </p>
                    </div>
                    <div className={styles.main_info_block_item}>
                        <img
                            src={AuthImage.images['feature-3.svg']}
                            width="100"
                            height="100"
                            alt=">Lots of tools"
                            className={styles.main_info_block_item_ico}
                        />
                        <h3 className={styles.main_info_block_item_title}>
                            Meaningful Communication
                        </h3>
                        <p className={styles.main_info_block_item_text}>
                            Take your time and get to know each other at your own pace before taking
                            the next step.
                        </p>
                    </div>
                    <div className={styles.main_info_block_item}>
                        <img
                            src={AuthImage.images['feature-4.svg']}
                            width="100"
                            height="82"
                            alt="Genuine interest"
                            className={styles.main_info_block_item_ico}
                        />
                        <h3 className={styles.main_info_block_item_title}>Verified Profiles</h3>
                        <p className={styles.main_info_block_item_text}>
                            We verify the authenticity of profiles to provide a trustworthy
                            experience.
                        </p>
                    </div>
                    <div className={styles.main_info_block_item}>
                        <img
                            src={AuthImage.images['feature-5.svg']}
                            width="100"
                            height="100"
                            alt="Manual profile verification"
                            className={styles.main_info_block_item_ico}
                        />
                        <h3 className={styles.main_info_block_item_title}>Advanced Matchmaking</h3>
                        <p className={styles.main_info_block_item_text}>
                            Our state-of-the-art matching algorithm takes into account your
                            preferences, hobbies, and relationship goals.
                        </p>
                    </div>
                    <div className={styles.main_info_block_item}>
                        <img
                            src={AuthImage.images['feature-6.svg']}
                            width="100"
                            height="100"
                            alt="Safety & Privacy"
                            className={styles.main_info_block_item_ico}
                        />
                        <h3 className={styles.main_info_block_item_title}>Secure and Private</h3>
                        <p className={styles.main_info_block_item_text}>
                            Your data is protected with top-notch security measures
                        </p>
                    </div>
                    <div className={styles.main_info_block_item}>
                        <img
                            src={AuthImage.images['feature-7.svg']}
                            width="100"
                            height="100"
                            alt="24/7 Help"
                            className={styles.main_info_block_item_ico}
                        />
                        <h3 className={styles.main_info_block_item_title}>24/7 Customer Support</h3>
                        <p className={styles.main_info_block_item_text}>
                            Our dedicated team works round the clock to ensure a delightful
                            experience on our platform.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
